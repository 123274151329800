import markerPin from './MarkerPinMaker';
import {Marker, Popup} from 'react-leaflet'
import L from 'leaflet';

function getMarkerType(entityTypeID){
    switch (entityTypeID) {
        case 2:
            return "Coletor"
        case 3:
            return "Gerador"
        case 5:
            return "Matriz"
        case 6:
            return "Matriz"
    
        default:
            break;
    }
}

export function markerFactor(marker){
    
    const entityTypeID = marker.entityTypeID;
    const sponsorStateID = marker.sponsor.stateID
    let markerType = getMarkerType(entityTypeID);

    //Novo endereço
    const entityAddress = `
    ${marker.streetAddress ? marker.streetAddress : ""}
    ${marker.streetNumber ? ", "+ marker.streetNumber : ""}
    ${marker.complementAddress ? ", " + marker.complementAddress : ""}
    ${marker.postalCode !== "" ? "(CEP: " + marker.postalCode + ")" : ""}
    ${marker.city !== "" ? ", " + marker.city : ""}
    ${marker.state !== "" ? "- " + marker.state : ""}
    `

    //Novo Pin
    const svgIcon = L.divIcon({
        html: markerPin(entityTypeID, sponsorStateID),
        className: "",
        iconSize: [24, 40],
        iconAnchor: [12, 40],
    });

    //Novo Popup
    const markerPopup = L.popup()
    .setLatLng([marker.latitude, marker.longitude])
    .setContent(`
        <p><span>Nome: ${marker.name}</span></p>
        <p><span>End.: ${entityAddress}</span></p>
        <p><span>Tipo.: ${markerType}</span></p>
        ${
            marker.residue !== null ?
            
            marker.residue.map((residuo) => {

                return (
                `<p><span>${residuo.name}: ${residuo.amount}${residuo.measurementUnit}</span></p>`
                )
            }).join("")
            
            : "<p><span>Sem resíduo especificado</span></p>"
        }
    `);


    //Retorna um novo marcador
    return L.marker(new L.LatLng(marker.latitude, marker.longitude), {
        icon: svgIcon,
        residue: marker.residue, //Retorna dados dentro de cada marcador
    }).bindPopup(markerPopup).openPopup()
}

export function SingleMarker(props){
    const userData = props.userData
    let markerType = getMarkerType(props.entityTypeID);

    const svgIcon = L.divIcon({
        html: markerPin(props.entityTypeID, ),
        className: "",
        iconSize: [24, 40],
        iconAnchor: [12, 40],
    });

    return (
        <Marker 
            position={[props.latitude, props.longitude]}
            icon={svgIcon} 
            >
            <Popup>
                <p><span>Nome: {userData.entity.name}</span></p>
                <p><span>End.: {userData.entity.streetAddress}, {userData.entity.streetNumber}</span></p>
                <p><span>Tipo.: {markerType}</span></p>
            </Popup>
        </Marker>
    )
}