import styled from 'styled-components';

export const HeadCard = styled.div`
    display: flex;
    align-items: center;
    align-content: center
    flex-direction: row;
    font-size: 25px;
    // font-weight: bold;
    gap: 0.5rem;
    color:#${props => props.whitelabel.primaryColor}
`;