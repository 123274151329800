//Firebase para o projeto teste

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//Firestore stuff
import { getFirestore } from '@firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyACUIZ0YlZ2dcjLQbdntiG9g1wlSbsNx4c", //API key do Loggisto
  authDomain: "loggisto-yatto.firebaseapp.com",
  projectId: "loggisto-yatto",
  storageBucket: "loggisto-yatto.appspot.com",
  messagingSenderId: "768295039204",

  // appId: "1:1041643104120:web:f37c8dfa3dc9d98f798a8d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)

// const analytics = getAnalytics(app);
