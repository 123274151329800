import React, { useContext } from 'react';
import Fluter from '../../parts/Fluter/Fluter';
import DownloadList from '../../parts/DownloadList/DownloadList';

import {Container, Content} from './styles';

import { UserContext } from '../../store/UserContext';

import checkingRoute from '../../store/checkingRoute';

import files from './data.json';

const CriticPoints = () => {

    const { menu, whitelabel } = useContext(UserContext);
    checkingRoute(menu, 2) // ID da área 2

    window.localStorage.setItem('yattoop', 'pontos_criticos');

    return (
        <Container>
            <Content whitelabel={whitelabel.systemProject[0].colors}>
                <DownloadList files={files.downloadFiles}/>
                <Fluter />
            </Content>
        </Container>     
                
    )

}

export default CriticPoints;