import { 
    doc, 
    getDoc, 
} from 'firebase/firestore';

import {db} from '../firebase/firebase-config';

export const getMenu = async (inputUserEntityId, setState) => { 

        const collectionRef = doc(db, "menu", inputUserEntityId)
        const data = await getDoc(collectionRef) 
        
        setState(() => {
            return {...data.data(), id: data.id}
        })
};