import React, { useContext } from 'react';

import { UserContext } from '../../store/UserContext';

import { Container, Content } from './styles';

import CardInfo from '../../parts/Card/Card';


const Home = () => {

    const { menu } = useContext(UserContext);

    return (
        <Container>
            <Content>
                { menu && menu.matriz.areas.map((item) => {
                   return (
                       <CardInfo
                            route={item.url}
                            title={item.name}
                            button={"Acessar"}
                            icon={item.icon}
                        />
                   )
                })
                };
            </Content>
        </Container>
    );
};

export default Home;