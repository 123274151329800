import React, { useContext } from 'react';

import { Container, FileInfo, Preview, FileOptions } from './styles'
import { MdFileDownloadOff, MdOutlineFilterAlt, MdBlock } from 'react-icons/md';

import excelIcon from '../../assets/excel.png'
import Download from '../Download/Download';
import { UserContext } from '../../store/UserContext';

const DownloadList = ({files}) => {

    const { whitelabel } = useContext(UserContext);

    return(
        <Container whitelabel={whitelabel.systemProject[0].colors}>
            {files.map( downloadFile => (
                <li key={downloadFile.name}>
                    <FileInfo whitelabel={whitelabel.systemProject[0].colors}>
                        <Preview src={excelIcon}/>
                        <div>
                            <strong>{downloadFile.name}</strong>
                            <span>
                                Extensão: {downloadFile.extension} 
                            </span>
                        </div>
                    </FileInfo> 
                    <FileOptions>
                            { downloadFile.filter === 1 ? <MdOutlineFilterAlt size={24} title={"Filtro disponível"} /> : <MdBlock size={24} title={"Filtro não disponível"} /> }

                                    { downloadFile.download && <Download 
                                            name={downloadFile.name} 
                                            type={downloadFile.extension}
                                            filter={downloadFile.filter}
                                            url={downloadFile.download}
                                        /> }
                                
                        {!downloadFile.download && <MdFileDownloadOff size={24} title={"Download não disponível"}/>}
                    </FileOptions>
                    </li>
            ))} 
        </Container>
    )

}

export default DownloadList;