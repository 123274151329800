import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import validateToken from '../services/validateTokenPost';
import { UserContext } from './UserContext';
import {getWhitelabel} from '../firestore/getWhitelabel';
import {getMenu} from '../firestore/getMenu';

import Login from '../views/Login/Login/Login'
const PrivateRoute = (props) => {

    const {login, setLogin, setData, setMenu, setWhitelabel} = useContext(UserContext);
    const history = useHistory();

    const yattoop = window.localStorage.getItem('yattoop');
    
    useEffect(() =>{

        async function validate(){
            const response = await validateToken()
            const data = response.data;

            switch(data.status){
                case 'ok' : 
                    return (
                        setLogin(true), setData(data.results[0].user),
                        getWhitelabel((data.results[0].user.entity.entityTypeID).toString(), setWhitelabel),
                        getMenu((data.results[0].user.entity.entityTypeID).toString(), setMenu)
                    );

                case 'error' :
                    window.localStorage.removeItem('yatto_token');
                    setLogin(false)
                break; 

                default: 
                return setLogin(false)
            }

        }validate();
    }, [setData, setLogin, setMenu, setWhitelabel]);
    
    switch(login){

        case true : 
            return (<Route {...props} />);

        case false :
            if(yattoop==='undefined'){

                return (history.push('/login/'), <Login/>)

            }else{
                
                return(history.push('/login/yattoop/'+yattoop), <Login />)
            }
           
        default :
            return null;
    }
}

export default PrivateRoute;