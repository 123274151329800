import { useState, createContext, useContext, useEffect } from 'react';
import { UserContext } from '../../../store/UserContext';
// import api from '../../../services/api'

import '../styles/styles.css'

import whitelabelJSON from '../../../whitelabel.json';

// Components
import Menu from './Menu';
import Icon from '../../IconGenerator/Icons';
import {ButtonOpenClose} from './ButtonOpenClose'

// Colors
const defaultColorTable = whitelabelJSON.systemProject.colors;

// Context
const SidebarContext = createContext();

function Sidebar (props) {
    const setReqControll = props.setReqControll;

    const [sidebarWidth, setSidebarWidth] = useState("19rem");
    const [sizeState, setSizeState] = useState(false);

    const { whitelabel, menu } = useContext(UserContext);

    const sidebarStyles = {
        width: sidebarWidth,
        backgroundColor: "#FCFDF3",
    }

    const resizeComponent = (resize) => {

        setSizeState(resize)

        setSidebarWidth(
            sizeState ?
            "19rem" :
            "6rem"
        )
    }

    //Navigation
    // const navigationData = props.navigationData.navigation;
    
    // Whitelabel
    // const whitelabel = props.whitelabel
    // const tabelaCores = whitelabel ? whitelabel.systemProject.cores : defaultColorTable;
    const tabelaCores = whitelabel.systemProject[0].colors;
        
    return (
        <SidebarContext.Provider 
        value={{
            sidebarWidth, setSidebarWidth, sizeState, setSizeState
        }}>
            <aside style={sidebarStyles}>
                <ButtonOpenClose
                    onClick={() => {resizeComponent(!sizeState)}}
                    whitelabel={tabelaCores}
                    sizeState={sizeState}
                    sidebarWidth={sidebarWidth}>

                    <Icon 
                        name="open-close" 
                        size={35}/>
                    
                </ButtonOpenClose>
                {/* <Logo logoSvg={whitelabel ? whitelabel.logo : "Foi não"}/> */}
                {/* <UserName usuario={usuario} colors={tabelaCores}/> */}

                    <nav>
                        <ul>
                            { 
                                menu && 
                                <Menu 
                                sizeState={sizeState}
                                setSizeState={setSizeState}
                                setSidebarWidth={setSidebarWidth}
                                navigationData={menu.matriz.areas} 
                                whitelabel={tabelaCores} 
                                setReqControll={setReqControll}/>  
                            }
                        </ul>
                    </nav>           
            </aside>
        </SidebarContext.Provider>
    ) 
}
    
export { Sidebar, SidebarContext};
