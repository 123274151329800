import styled from 'styled-components';

export const ErrorStyled = styled.div`
        width: 100%;
        height: 2rem;
        color: #${props => props.whitelabel.whiteColor};
        display: grid;
        grid-template-columns: 0.4fr  7fr;
        background: #${props => props.whitelabel.errorColor};
        border-radius: 0.3rem;
        text-align: center;
        margin-bottom: auto;
        margin-top: auto;
        padding: 0.5rem;
`;
