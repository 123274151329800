
import styled from 'styled-components'

//Retorno padrão dos itens do botão
export const ButtonOpenClose = styled.div`
    ${props => props.style}
    color: #${props => props.whitelabel.neutralPrimaryColor};
    background-color: #FCFDF3;
    border-radius: 50%;
    // border: 2px solid #${props => props.whitelabel.grayLight};
    filter: drop-shadow(3px 2px 2px #99999978);
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: calc(${props => props.sidebarWidth} + 1rem);
    transition: .5s;

        svg {
            transition: .02s;
            transform: 
                scale(${props => props.sizeState? 1 : -1 }, 1) 
                translate(${props => props.sizeState? "0" : "2px" }, 0);
            fill: #${props => props.whitelabel.neutralPrimaryColor};
        }

    &:hover {
        background-color: #${props => props.whitelabel.primaryColor};
        svg{
            fill: #${props => props.whitelabel.whiteColor};
        }

    }

`
