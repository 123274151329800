import mothership from './mothership';

function tokenPost(body){

    const anwser = mothership.post("/authentication", 
        JSON.stringify(body), {
            headers : {
                'Accept': 'application/json', 
                'Content-Type': 'Content-Type'
            },
        }).then((response) => {
            return response;
            })
            .catch((err) => {
                console.error("Ocorreu um erro:" + err.response);
                return err.response;
            });

    return anwser;
};

export default tokenPost;