import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import getImage from '../../../firestore/getImage';

import { UserContext } from '../../../store/UserContext';

import LoginForm from '../LoginForm/LoginForm';

import { Container, BoxForm, Logo, LoginBox, Infos } from './styles';


const Login = () => {
    
    const { yattoop } = useParams();
    const { whitelabel } = useContext(UserContext);
    window.localStorage.setItem('yattoop', yattoop);

    const icon = getImage(whitelabel.systemProject[0].logo.icon)
   
    return (
        <Container>
            <LoginBox image={""} whitelabel={whitelabel.systemProject[0].colors} >
                <BoxForm>
                    <Logo image={icon} />
                    <LoginForm whitelabel={whitelabel.systemProject[0].colors}/>
                    <Infos>Precisa de uma nova senha?<br/>Fale com com o <b>contato@residual.org</b></Infos>
                </BoxForm>
            </LoginBox>
        </Container>
    )

};

export default Login;