import React, { useContext } from 'react';
import Dropzone from 'react-dropzone';
import { UserContext } from '../../store/UserContext';
import { DropContainer, UploadMessage } from './styles';


const renderDragMessage = (isDragActive, isDragReject, whitelabel) => {

    if(!isDragActive) {
        return <UploadMessage whitelabel={whitelabel.grayDark}> Arraste arquivos aqui...</UploadMessage>
    }

    if(isDragReject){
        return <UploadMessage type="error" whitelabel={whitelabel.errorColor}>Arquivo não suportado</UploadMessage>
    }

    return <UploadMessage type="success" whitelabel={whitelabel.primaryColor}> Solte os arquivos aqui </UploadMessage>
};

const Upload = ({onUpload}) => {

    const { whitelabel } = useContext(UserContext)

    return (
        <Dropzone accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onDropAccepted={onUpload}>
            { ({ getRootProps, getInputProps, isDragActive, isDragReject}) =>(
                <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    whitelabel={whitelabel.systemProject[0].colors}
                >
                    <input {...getInputProps()} />
                    {renderDragMessage(isDragActive, isDragReject, whitelabel.systemProject[0].colors )}
                </DropContainer>
            )}
        </Dropzone>
    )
};

export { Upload, renderDragMessage }