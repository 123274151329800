import React from 'react';

import style from './Error.module.css';

import { ErrorStyled } from './styles';
import { MdError } from 'react-icons/md'

const Error = ({error, whitelabel}) => {
    
    if(!error) return null;

    return (
        <ErrorStyled whitelabel={whitelabel}>
            <MdError />
            {error}
        </ErrorStyled>
        // <div className={style.error}>
        //     {error}
        // </div>
    )
};

export default Error;