import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 6rem;

`;

export const Content = styled.div`{
    width: 100%;
    max-width: 100%;
    margin: 30px;
    grid-template-columns: 1fr 1fr;
    border-radius: 4px;
    gap: 1rem;
    padding: 1rem;
    display:grid;
    grid-gap: 30px 20px;
}`

