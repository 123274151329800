import styled from 'styled-components'

export const HeaderStyled = styled.header`
    width: 100%;
    height: 4rem;
    position: fixed; 
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 1rem;
    background-color: #${props => props.whitelabel.primaryColor};
    color: #${props => props.whitelabel.whiteColor};
    
`;

export const Icon = styled.div`
    background-image: url(${props => props.image});
    width: 3rem;
    height: 3rem;
    background-size:cover;
    background-size: 3rem 3rem;
    background-repeat: no-repeat;
    background-position: center;
    cursor:pointer;

`

export const Infos = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`
export const PointerExit = styled.div`
    cursor: pointer;
`