import { useEffect, useState } from 'react';

import { 
    ref, 
    getStorage,
    getDownloadURL,
} from 'firebase/storage';

const FirestoreImage = (urlImage) => {

    const [image, setImage] = useState("")
    const storage = getStorage();

    const getImage = async () => {
        getDownloadURL(ref(storage, `${urlImage}`))
        .then((url) => {
            setImage(url);
        })
        .catch((error) => {
        });
    }
    
    useEffect(() => {
        getImage()
    }, [urlImage])

    return image;
}

export default FirestoreImage;