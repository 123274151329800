
// router
import MenuOption from './MenuOption';

import { useContext } from 'react';
import { UserContext } from '../../../store/UserContext';

const Menu = props => {

    
    
    const setReqControll = props.setReqControll;
    const navigationData = props.navigationData;
    // const whitelabel = props.whitelabel;
    const { whitelabel } = useContext(UserContext);
    
    return (

        // Renderiza opções do menu
        navigationData.map(function(item, index) {
          
            return (
                <MenuOption 
                    item={item}
                    setReqControll={setReqControll}
                    whitelabel={whitelabel.systemProject[0].cores}
                    />
            )
        })
    );
}
        
export default Menu;
        