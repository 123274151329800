const filterFields = [
    {
        id: 'entityName', 
        label : 'Nome',
        placeholder : "Nome para busca (nome fantasia ou razão social)",
        type : 'text'
    },
    {
        id: 'entityCode', 
        label : 'Código',
        placeholder : 'Código da busca',
        type : 'text'
    },
    {
        id: 'entityAddress', 
        label : 'Endereço',
        placeholder: 'Endereço da busca',
        type : 'text'
    },
    {
        id: 'startRegisterDate', 
        label : 'Data de cadastro inicial',
        type : 'date'
    },
    {
        id: 'endRegisterDate', 
        label : 'Data de cadastro final',
        type : 'date'
    },
    {
        id: 'startActivationDate', 
        label : 'Data de ativação inicial',
        type : 'date'
    },
    {
        id: 'endActivationDate', 
        label : 'Data de ativação final',
        type : 'date',
    },
    {
        id: 'initservicedate', 
        label : 'Data de serviço inicial',
        type : 'date'
    },
    {
        id: 'endservicedate', 
        label : 'Data de serviço final',
        type : 'date',
    },
    {
        id: 'residuetype',
        label : 'Resíduos',
        type : 'select',
        options : 'residueFilter'
    },
    {
        id: 'tag',
        label : 'Etiquetas',
        type : 'select',
        options : 'tagFilter'
    },
    {
        id: 'entityStatus',
        label : 'Status da atividade',
        type : 'select',
        options : 'operationalStateFilter'
    },
    {
        id: 'entitySLRStatus',
        label : 'Entidade no programa',
        type : 'select',
        options : 'SLRStateFilter'
    },
    {
        id: 'entityMatrice',
        label : 'Matriz da entidade no programa',
        type : 'select',
        options : 'SLRMatriceFilter'
    },
    {
        id: 'entityHauler',
        label : 'Coletor da entidade no programa',
        type : 'select',
        options : 'SLRHaulerFilter'
    }

]

export default filterFields;