import styled from 'styled-components';

export const Container = styled.div`
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #${props => props.whitelabel.darkGray};
      border-bottom:solid 1px #${props => props.whitelabel.grayDark};
      padding:0.5rem;
      border-radius: 5px;
      margin-bottom: 0.5rem;

        & + li {
            margin-top: 15px;
        }
    }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: #${props => props.whitelabel.gray};
      margin-top: 5px;    
    }
  }
`;

export const Preview = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 10px;
    filter: grayscale(${props => props.uploaded ? '100%' : '0%' });
`;

export const Message = styled.div`
  padding: 0.2rem 0 0 0;
  border-radius: 5px;
  font-size:12px;
  color:${props => props.error ? `#`+props.whitelabel.errorColor : `#`+props.whitelabel.primaryColor };

`;