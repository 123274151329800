import mothership from "./mothership";

function validateToken(){
    
    const localToken = window.localStorage.getItem('yatto_token');

    const anwser = mothership.get("/authorization", 
        {    
            headers : {
                'Accept': 'application/json', 
                'Content-Type': 'Content-Type',
                'Authorization' : 'Bearer '+ localToken
            },
        }).then((response) => {
            return response;
            })
            .catch((err) => {
                console.error("Ocorreu um erro:" + err.response);
                return err.response;
            });

    return anwser;
};

export default validateToken;