import styled from 'styled-components';

export const InputStyled = styled.input`
    border: 1px solid #${props => props.whitelabel.neutralPrimaryColor};
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 0.4rem;
    background: #eee;
    transition: 0.2s;
    margin-bottom: 0.5rem;

    &:focus,
    &:hover {
        outline: none;
        border-color: #${props => props.whitelabel.neutralPrimaryColor};;
        background: #fff;
        box-shadow: 0 0 0 2px #${props => props.whitelabel.neutralPrimaryColor};
    }

`