import { useEffect } from 'react';

//Leaflet
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet.markercluster';

import { markerFactor } from './MarkerFactor';

let markerListLength2 = 0
let markerListLength3 = 0
let markerListLength5 = 0

//Novos marker Cluster Groups
const mcg2 = L.markerClusterGroup({ //Coletor
  chunkedLoading: true,
  showCoverageOnHover: false,
  iconCreateFunction: function (cluster) {
        
    //Marcadores dentro do cluster
    const childList = cluster.getAllChildMarkers()

    //Quantidade de marcadores
    const childCount = cluster.getChildCount();

    //Calcular matiz da cor
    const colorHueValue = markerListLength2 > 0 ? 
    ((100*childCount)/markerListLength2)/3
    : 0.1

    //Calcular saturação da cor
    const colorSaturationValue = markerListLength2 > 0 ? 
    2 *((100*childCount)/markerListLength2)
    : 0.1

    const fontColor = colorSaturationValue < 0.5 ?
    "#000000"
    : "#FFFFFF"

    //Novas classes com base na quantidade de marcadores
    // let c = ' marker-cluster-';

    // if (childCount < 10) {
    //   c += 'small';
    // } 
    // else if (childCount < 100) {
    //   c += 'medium';
    // } 
    // else {
    //   c += 'large';
    // }
    
    return new L.DivIcon({
      html: `<div style="
      background-color: hsl(
        calc(${colorHueValue} + 240), calc(${colorSaturationValue}% + 35%), 50%);
        "><span>
          ${childCount}
        </span></div>`, 
      className: 'marker-cluster-coletor' /*+ c*/, 
      iconSize: new L.Point(40, 40) 
    });
  }
  
  //tooltip
}).on('clustermouseover', function(ev) {
  
  //Marcadores dentro do cluster
  const childList = ev.propagatedFrom.getAllChildMarkers()

  //Lista de residuos por cluster
  const tooltipResidues = {}

  //Mapear os marcadores e retornar o valor de cada tipo de resíduo.
  childList.map(entity => {

    const residueList = entity.options.residue;
    
    return residueList !== null ?
    residueList.map(residue => {

      if(tooltipResidues[residue.name]){

        tooltipResidues[residue.name].value += residue.amount
      } else {

        tooltipResidues[residue.name] = {

          value: residue.amount,
          measurementUnit: residue.measurementUnit
        }
      }
      
    }) : null

  })

  //Montando o tooltip
  ev.propagatedFrom.bindTooltip(`${
    Object.keys(tooltipResidues).map(function(key, index) {
      return `
        <p><span>${key}: ${tooltipResidues[key].value.toFixed(2)}${tooltipResidues[key].measurementUnit}</span></p>
      `
  }).join("")}`, {sticky: true}).openTooltip();

}).on('clustermouseout', function(ev) {

  ev.propagatedFrom.unbindTooltip();

});;

const mcg3 = L.markerClusterGroup({ //Gerador
  chunkedLoading: true,
  showCoverageOnHover: false,
  iconCreateFunction: function (cluster) {

    //Quantidade de marcadores
    const childCount = cluster.getChildCount();

    //Calcular matiz da cor
    const colorHueValue = markerListLength3 > 0 ? 
    ((100*childCount)/markerListLength3)/3
    : 0.1

    //Calcular saturação da cor
    const colorSaturationValue = markerListLength3 > 0 ? 
    30*((100*childCount)/markerListLength3)
    : 0.1

    const fontColor = colorSaturationValue < 0.5 ?
    "#000000"
    : "#FFFFFF"

    //Novas classes com base na quantidade de marcadores
    // let c = ' marker-cluster-';

    // if (childCount < 10) {
    //   c += 'small';
    // } 
    // else if (childCount < 100) {
    //   c += 'medium';
    // } 
    // else {
    //   c += 'large';
    // }
    
    return new L.DivIcon({
      html: `<div style="
      background-color: hsl(
        calc(${colorHueValue} + 90), calc(${colorSaturationValue}% + 30%), 50%);
        "><span>
          ${childCount}
        </span></div>`, 
      className: 'marker-cluster-gerador' /* + c*/, 
      iconSize: new L.Point(40, 40) 
    });
  }

  //tooltip
}).on('clustermouseover', function(ev) {
  
  //Marcadores dentro do cluster
  const childList = ev.propagatedFrom.getAllChildMarkers()

  //Lista de residuos por cluster
  const tooltipResidues = {}

  //Mapear os marcadores e retornar o valor de cada tipo de resíduo.
  childList.map(entity => {

    const residueList = entity.options.residue;
    
    return residueList !== null ?
    residueList.map(residue => {

      if(tooltipResidues[residue.name]){

        tooltipResidues[residue.name].value += residue.amount
      } else {

        tooltipResidues[residue.name] = {

          value: residue.amount,
          measurementUnit: residue.measurementUnit
        }
      }
      
    }) : null

  })

  //Montando o tooltip
  ev.propagatedFrom.bindTooltip(`${
    Object.keys(tooltipResidues).map(function(key, index) {
      return `
        <p><span>${key}: ${tooltipResidues[key].value.toFixed(2)}${tooltipResidues[key].measurementUnit}</span></p>
      `
  }).join("")}`, {sticky: true}).openTooltip();

}).on('clustermouseout', function(ev) {

  ev.propagatedFrom.unbindTooltip();

});;

const mcg5 = L.markerClusterGroup({ //Matriz
  chunkedLoading: true,
  showCoverageOnHover: false,
  iconCreateFunction: function (cluster) {
        
    //Marcadores dentro do cluster
    const childList = cluster.getAllChildMarkers()
    console.log(childList) 

    //Quantidade de marcadores
    const childCount = cluster.getChildCount();

    //Calcular matiz da cor
    const colorHueValue = markerListLength5 > 0 ? 
    ((100*childCount)/markerListLength5)/3
    : 0.1

    //Calcular saturação da cor
    const colorSaturationValue = markerListLength5 > 0 ? 
    2 *((100*childCount)/markerListLength5)
    : 0.1

    const fontColor = colorSaturationValue < 0.5 ?
    "#000000"
    : "#FFFFFF"

    
    //Novas classes com base na quantidade de marcadores
    // let c = ' marker-cluster-';

    // if (childCount < 10) {
    //   c += 'small';
    // } 
    // else if (childCount < 100) {
    //   c += 'medium';
    // } 
    // else {
    //   c += 'large';
    // }
   
    return new L.DivIcon({
      html: `<div style="
      background-color: hsl(
        ${colorHueValue}, 100%), 50%);
        "><span>
          ${childCount}
        </span></div>`, 
      className: 'marker-cluster-matriz' /*+ c*/, 
      iconSize: new L.Point(40, 40) 
    });
  }
  
  //tooltip
}).on('clustermouseover', function(ev) {
  ev.propagatedFrom.bindTooltip(`
  <p><span>Teste Tooltip1</span></p>
  <p><span>Teste Tooltip2</span></p>
  `, {sticky: true}).openTooltip();
}).on('clustermouseout', function(ev) {
  ev.propagatedFrom.unbindTooltip();
})

function MarkerClusterGroup (props){
  
  const markers = props.markers
  const map = useMap();
  
  useEffect(() => {
    mcg2.clearLayers();
    mcg3.clearLayers();
    mcg5.clearLayers();

    //Arrays para armazenar cada tipo de marcador.
    let markerList2 = []
    let markerList3 = []
    let markerList5 = []

    markers.map((marker) => {

      //Separar marcador por tipo de cluster
      switch (marker.entityTypeID) {
        case 2:
          markerList2.push(markerFactor(marker));
          break;
        case 3:
          markerList3.push(markerFactor(marker));
          break;
        case 5:
          markerList5.push(markerFactor(marker));
          break;
      
        default:
          break;
      }

      return null
    });

    //Clusters por entidade
    mcg2.addLayers(markerList2);
    mcg3.addLayers(markerList3);
    mcg5.addLayers(markerList5);

    markerListLength2 = markerList2.length
    markerListLength3 = markerList3.length
    markerListLength5 = markerList5.length

    // console.log(markerList3)

    // map.fitBounds(mcg.getBounds());

    //Implementar clusters no mapa
    map.addLayer(mcg2);
    map.addLayer(mcg3);
    map.addLayer(mcg5);
  }, [markers, map]);

  return null;
};

export default MarkerClusterGroup

// Referência: https://stackoverflow.com/questions/66545536/how-to-remove-marker-from-markercluster