import { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { UserContext } from './UserContext';

export default function CheckingRoute(menu, areaId) {

    const history = useHistory();

    const check = menu && menu.matriz.areas.filter((item) => item.id === areaId);
    const condit = check && check.length;

    const { setAccessDenied } = useContext(UserContext);

    if(condit === 0){
        setAccessDenied(true);
        history.push('/');
        
    }else{
        return null;
    }

};

