import styled from 'styled-components';

export const Container = styled.div`

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #${props => props.whitelabel.gray};
        border-bottom:solid 1px #${props => props.whitelabel.grayDark};
        padding:0.5rem;
        border-radius: 5px;
        margin-bottom: 0.5rem;


        & + li {
            margin-top: 15px;
        }
    }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: #${props => props.whitelabel.gray};
      margin-top: 5px;
    }
  }
`;

export const FileOptions = styled.div`
  width: 25rem;
  display: flex;
  justify-content: space-between;

`;

export const Preview = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 10px;
`;