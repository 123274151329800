import { React, useContext } from 'react';
import global from '../../services/global';
import { UserContext } from '../../store/UserContext';

import { useState } from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { MdFileDownload } from 'react-icons/md';

import { Container } from './styles';

// https://stackoverflow.com/questions/52030158/can-we-use-ondownloadprogress-from-axios-for-loading-api



const Download = ({ url, name, type, filter, }) => {

    const [progress, setProgress] = useState(0);

    const yattoop = window.localStorage.getItem('yattoop');
    let filterOp = null;

    const { filterDownload, filterLists, whitelabel } = useContext(UserContext)

    switch (yattoop) {
        case 'download':
            if (filter === 1) filterOp = filterDownload;
            break;

        case 'listas':
            if (filter === 1) filterOp = filterLists;
            break;

        case 'pontos_criticos':
            if (filter === 1) filterOp = filterLists;
            break;
    }

    async function download() {

        const localToken = window.localStorage.getItem('yatto_token');
        await global.get(url,
            {
                responseType: 'blob',
                params: filterOp,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localToken,
                },
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);

                }
            }

        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
            const link = document.createElement("a");

            link.href = url
            link.setAttribute("download", name + '.' + type);

            document.body.appendChild(link)

            link.click()
        })
    }

    return (
        <Container>
            <MdFileDownload
                onClick={download}
                size={24}
                style={{ cursor: 'pointer' }}
                title={"Clique para iniciar o download"}
            />

            <CircularProgressbar
                styles={{
                    root: { width: 24 },
                    path: { stroke: `#${whitelabel.systemProject[0].colors.primaryColor}` },
                    text: { fill: '#000' }

                }}
                strokeWidth={10}
                text={`${progress}%`}
                value={progress}
            />
        </Container>
    )




}

export default Download;