import styled from 'styled-components';

export const ButtonStyled = styled.button` 
    font-size: 1rem;
    font-family: var(--type-first);
    cursor :pointer;
    border: none;
    border-radius: 0.4rem;
    background: #${props => props.whitelabel.primaryColor};
    color:#eee;
    padding: .8rem 1.2rem;
    box-sizing: border-box;
    transition: 0.1s;
    min-width: 8rem;

    &:hover {
        outline: none;
        box-shadow: 0 0 0 3px #${props => props.whitelabel.secondaryColor};
        background: #${props => props.whitelabel.quartenaryColor}
    }

    &:disabled {
        opacity: 0.5;
        cursor: wait;
    }
`

