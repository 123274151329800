import styled from 'styled-components'

export const DropContainer = styled.div.attrs({
    className: "dropzone"
})`
    border: 1px dashed #${props => props.whitelabel.primaryColor};
    border-radius: 4px;
    cursos: pointer;
    transition: height 0.2 ease;
    border-color:${props => props.isDragActive ? `#`+props.whitelabel.primaryColor : null };
    border-color:${props => props.isDragReject ? `#`+props.whitelabel.errorColor : null };

`;

const messageColors = {
    default: '#999',
    error: '#e57878',
    success: '#326c3b',
}

export const UploadMessage = styled.p`
    display: flex;
    color: #${props => props.whitelabel};
    justify-content: center;
    align-items:center;
    padding: 15px 0px;

`;