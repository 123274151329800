import React ,{ useContext } from 'react';
import { UserContext } from '../../store/UserContext';

import { Container, Content } from './styles';

import Fluter from '../../parts/Fluter/Fluter';
import DownloadList from '../../parts/DownloadList/DownloadList';

import checkingRoute from '../../store/checkingRoute';

import lists from './data.json';


const ListsArea = () => {

    const { menu, whitelabel } = useContext(UserContext)

    checkingRoute(menu, 3) // ID da área 3
    

    window.localStorage.setItem('yattoop', 'listas');
    
    return (
        <Container>
            <Content whitelabel={whitelabel.systemProject[0].colors}>
                <DownloadList files={lists.downloadFilesLists}/>
                <Fluter />
            </Content>
        </Container>
    );
};

export default ListsArea;