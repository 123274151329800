import mothership from './mothership';

function filterGet(){

    const localToken = window.localStorage.getItem('yatto_token');


    const anwser = mothership.get("/system/filter", 
        {
            headers : {
                'Accept': 'application/json', 
                'Content-Type': 'Content-Type',
                'Authorization': 'Bearer '+localToken
            }
        }).then((response) => {
            return response;
            }).catch((err) => {
                console.error("Ocorreu um erro:" + err);
            });

    return anwser;
};

export default filterGet;