import styled from 'styled-components';

export const Container = styled.div`
    max-width: 90%;
    max-height: 90%;
    padding: 5%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto; 
    margin-bottom: auto;
    `
    
export const BoxForm = styled.div`
    max-width: 100%;
    padding: 0rem, 1rem, 1rem, 1rem;
    vertical-align: middle;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    
`

export const Logo = styled.div`
    width: 100%;
    height: 4rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${props => props.image});
    //background-image: url('https://firebasestorage.googleapis.com/v0/b/loggisto-yatto.appspot.com/o/logo-images%2FLogo-Cor-Completa-Fundo-Transparente_Yatto%203.png?alt=media&token=5efd8555-5c2f-405b-99ba-c7ab7a4402a5');
    margin-bottom: 1rem;
`

export const LoginBox = styled.div`
        display: grid;
        grid-template-columns : 1fr 1fr;
        min-height: 100vh;
        gap: 2rem;
        border-radius: 1%;
        padding: 5%;
        box-shadow: 10px 10px 5px 5px #${props => props.whitelabel.quartenaryColor};

        &::after {
            display: block;
            content: '';
            // background-image: url(${props => props.image});
            background-image: url('https://cdn.dribbble.com/users/2087893/screenshots/15027260/media/ac29042cc76a0c2abb33ba12dc30f37f.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            // background-color: #${props => props.whitelabel.primaryColor};
            // background-color: pink
        }
`;

export const Infos = styled.div`
    margin-bottom: auto;
    margin-top: 5rem;
`