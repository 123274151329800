import React, { useContext } from 'react';
import { Link } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import Button from '../Form/Button/Button';
import { Typography } from '@material-ui/core';

import Icon from "../IconGenerator/Icons";
import { HeadCard } from './styles';
import { UserContext } from '../../store/UserContext';

import { useHistory } from 'react-router-dom';

const ComponentListDescription = {
    'Pontos Críticos' : "Nessa área você pode realizar o download das planilhas de pontos críticos, contando com seus vários tipos.",
    'Mapas': "Nessa área você pode visualizar o mapa com pontos geradores, matrizes e tudo o que diz respeito aos pontos do programa de logística reversa!",
    'Área de Upload' : "Nessa área você pode subir planilhas de cadastro de entidades, matrizes, projetos e muito mais!",
    'Área de Listas' : "Precisa de ajuda para realizar o upload? Aqui você tem acesso ao download de planilhas padrões para preencher.",
};

const CardInfo = ({title, content, button, route, icon }) => {

    const { whitelabel } = useContext(UserContext);
    const history = useHistory();

    return (
        <Card sx={{ maxWidth: '100%', backgroundColor : `#${whitelabel.systemProject[0].colors.grayLight}`, padding : '1rem'}} >
            <CardContent>
                <HeadCard whitelabel={whitelabel.systemProject[0].colors}>
                    <Icon 
                        name={icon} 
                        size={50} 
                        color={whitelabel.systemProject[0].colors.primaryColor}/>
                     | {title} 
                </HeadCard>
                <Typography variant="body2" color="text.secondary">
                    {ComponentListDescription[title]}
                </Typography>
            </CardContent>
            <CardActions>
                {/* <Link href={route} underline="none">  */}
                    <Button onClick={() => { history.push(route)}}>{button}</Button>
                {/* </Link> */}
            </CardActions>
    </Card>
    )
}

export default CardInfo;