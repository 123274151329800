import { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom';

import Icon from "../../IconGenerator/Icons"
import { ButtonItem } from "./ButtonEstiloso"

// Context
import { SidebarContext } from './Sidebar';
import { UserContext } from '../../../store/UserContext';

export const MenuOption = (props) => {

    // Estados componente pai
    const {sizeState, setSizeState, setSidebarWidth } = useContext(SidebarContext);
    const { whitelabel } = useContext(UserContext);

    const setReqControll = props.setReqControll;

    const item = props.item;

    const renderSubmenuItens = (submenu, paiID) => {

        return submenu.map(function(item, index) {

            // Estilização dinâmica para cada opção no menu.
            const subItemStyle = {
                "display": "flex",
                "alignItems": "center",
                "marginBottom": "2px"
            }
            
            return (
                <div className={`Submenu-Item`} id={paiID}>
                    <ButtonItem
                    key={`${item.ID}p${paiID}`}
                    style={openMenu ? subItemStyle : null}
                    tabelaCores={whitelabel.systemProject[0].colors}
                    className="m-item d-item-drop"
                    onClick={() => onClickMenu(item.route, item.ID, !openMenu)}>
                            <Icon name={item.icon} size={50}/>
                            <p>{item.name} </p>
                    </ButtonItem>
                </div>
            )
        })
    }
    
    // renderiza o efeito de dropdown de acordo com o valor em "openMenu"
    const [openMenu, setOpenMenu] = useState(false)
    const [openMenuIcon, setOpenMenuIcon] = useState("→")
    
    // Recebe uma string em "route" como parâmetro
    const pushToRoute = route => {
        props.history.push(route)
        setOpenMenu(false)
    }

    // Abre um menu específico
    const onClickAbrirMenu = (estadoMenu) =>{

        setSizeState(false)
        setOpenMenu(estadoMenu)

        setOpenMenuIcon(estadoMenu ? "↓" : "→")
        setSidebarWidth("19rem")
    }

    const onClickMenu = (route, id, estadoMenu) => {
        pushToRoute(route)
        setReqControll(id)
        setOpenMenuIcon(estadoMenu ? "↓" : "→")
    }

    // Estilização dinâmica para cada opção no menu.
    const itemStyle = {
        "display": "flex",
        "alignItems": "center",
        "marginBottom": "2px"
    }

    return (
        
        <div className="Menu-Item" id={`Menu-${item.ID}`}>
            <ButtonItem  
            key={item.ID}
            style={itemStyle} 
            tabelaCores={whitelabel.systemProject[0].colors}
            className={`m-item m-logo`}
            onClick={() => {

                //Abrir se houver submenu / subnavigation

                if(item.subNavigation){
                    onClickAbrirMenu(!openMenu)
                } else {
                    onClickMenu(item.url, item.ID, !openMenu)
                } 
            }}>
                <Icon 
                name={item.icon} 
                size={50} />

                {!sizeState ? 
                    <p >
                        {item.name} &nbsp; { item.subNavigation ? openMenuIcon : null }
                    </p> :

                    <p> &nbsp; </p>
                }
            </ButtonItem>
            
            {item.subNavigation && !sizeState ? renderSubmenuItens(item.subNavigation, item.ID) : null}
            
        </div>
        
    )
}

export default withRouter(MenuOption)