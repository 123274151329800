
import styled from 'styled-components'

//Retorno padrão dos itens do botão
export const ButtonItem = styled.div`
    ${props => props.style}
    border-bottom: 1px solid #${props => props.tabelaCores.neutralPrimaryColor};
    color: #${props => props.tabelaCores.neutralPrimaryColor};
    white-space:nowrap;
    svg{

        fill: #${props => props.tabelaCores.neutralPrimaryColor};
    }

    &:hover{
        border-left: 4px solid #${props => props.tabelaCores.neutralPrimaryColor};
        p{
            color: #${props => props.tabelaCores.primaryColor};
        }

        svg{

            fill: #${props => props.tabelaCores.primaryColor};
        }
    }

`