import { useState } from 'react'

const types = {
    email : {
        regex: /^[\w._]+@[\w]+\.[\w]+/,
        caracters : 10,
        message: 'E-mail inválido!'
    },
    password : {
        regex: /[\w]/,
        caracters : 3,
        message: 'Senha inválida!'
    }
}; 

const useForm = (type) => {

    const [value, setValue] = useState('');
    const [error, setError] = useState(null);

    function validate(value){

        if(type === false) return true;

        if(value.length === 0) {
            setError('Preencha com um valor!')
            return false;
        } else if(types[type] && ! types[type].regex.test(value)) {
            setError(types[type].message);
            return false;
        }else if(types[type] && value.length <= types[type].caracters ) {
            setError(types[type].message);
            return false;

        }else{
            setError(null);
            return true;
        }

    }

    function onChange({target}) {
        if (error) validate(target.value);
        setValue(target.value);
    }

    return {
        value, 
        setValue,
        onChange,
        error,
        validate: () => validate(value),
        onBlur: () => validate(value),
    } 
    
}

export default useForm;