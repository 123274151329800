import React, { Component } from "react";
import Icons from "../../assets/icons.svg";
// import Icons from "../../icons.svg";

class Icon extends Component {
  constructor(props){
    super(props)
    this.name = props.name;
    this.size = props.size;
    this.whitelabel = props.whitelabel;
    // this.color = this.mudaCor()
    
    this.state = {
      color: this.mudaCor(),
    }

    //Método para mudança de cor
    this.mudaCor = this.mudaCor.bind(this);

  }

  mudaCor(){

    return this.props.color;
    
  }


  render (){
    return (
      <svg className={`icon icon-${this.name}`} fill={"#"+this.mudaCor()} width={this.size} height={this.size}>
        <use xlinkHref={`${Icons}#icon-${this.name}`} />
      </svg>
    )
  }
}

//Referência: https://stackoverflow.com/a/42296853/15856324

export default Icon;

