import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.module.css';
import Routes from '../routes';

const routes = [
  {
    component: 'MapsArea',
    filter: true,
    name: "Mapas",
    id : 1,
    roleID : [1,2,3],
    url : "/yattoop/mapas"
  },
  {
    component: 'CriticPoints',
    filter: true,
    name: "Pontos críticos",
    id: 2,
    roleID : [1,2,3,4],
    url : "/yattoop/pontos_criticos"
  },
  {
    component: 'ListsArea',
    filter: true,
    name: "Área de listas",
    id : 3,
    roleID : [1,2,3,4],
    url : "/yattoop/listas"
  },
  {
    component: 'UploadArea',
    filter: false,
    name: "Área de Upload",
    id: 4,
    roleID : [1,2,3,4],
    url : "/yattoop/upload"
  }
]


function App() {

  return (
    <>
      <BrowserRouter>
         <Routes userRoutesRequire={routes}/> 
      </BrowserRouter>
    </>
  );
}

export default App;
