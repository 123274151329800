import mothership from './mothership';

function mapGet(token, filter){

    const anwser = mothership.get("/map/entity", 
        {
            params: filter,
            headers : {
                'Accept': 'application/json', 
                'Content-Type': 'Content-Type',
                'Authorization': `bearer ${token}`
            }
        }).then((response) => {
            return response;
            
            }).catch((err) => {

                console.error("Ocorreu um erro:" + err);
            });

    return anwser;
};

export default mapGet;