import { 
    doc, 
    getDoc, 
} from 'firebase/firestore';

import {db} from '../firebase/firebase-config';

export const getWhitelabel = async (inputUserEntityId, setState) => { //Busca whitelabel padrão no firestore

        const docRef = doc(db, "whitelabel", inputUserEntityId)
        const data = await getDoc(docRef) 

        setState((() => {
            return {...data.data(), id: data.id}
        }))
};
