import React, { useContext } from 'react';

import Input from '../../../parts/Form/Input/Input'
import Button from '../../../parts/Form/Button/Button';
import useForm from '../../../hooks/useForm';
import Error from '../../../parts/Error/Error';

import styles from './LoginForm.module.css'

import { UserContext } from '../../../store/UserContext';
import Checkbox from '@material-ui/core/Checkbox';

function showPassword() {
    var password = document.querySelector('input[name="password"]');
    password.type === 'password' ? password.type = 'text' :  password.type = 'password';
}

const LoginForm = ({whitelabel}) => {

    const email = useForm('email');
    const password = useForm('password');

    const {userLogin, error, load} = useContext(UserContext); //comentar para teste
  
    async function handleSubmit(event) {
        event.preventDefault();

        if(email.validate() && password.validate()) {
            userLogin(email.value, password.value);    //comentar para teste 
        };
    };
   

    return (
        <>
            <section className="animeLeft">
                <h1 className="title">Login</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <Input
                        label="E-mail"
                        type="text"
                        name="email"
                        whitelabel={whitelabel}
                        {...email}
                    />
                    <Input
                        label="Senha"
                        type="password"
                        name="password"
                        id="password"
                        whitelabel={whitelabel}
                        {...password}
                    />
                    <div className={styles.showPassword}> 
                        <Checkbox
                            onClick={showPassword}
                            sx={{
                                color: `#${whitelabel.primaryColor}`,
                                '&.Mui-checked': {
                                color: `#${whitelabel.primaryColor}`,
                                },
                            }} />
                        <label className={styles.forget}>Mostrar a senha </label>
                    </div>
                    {load ? <Button disabled>Carregando...</Button> : <Button data-testid="login">Login</Button> } 
                    <br/>
                    <Error whitelabel={whitelabel} error={error}/>
                    {/* Para testar, deixar apenas o button sem a condição load e error sem props */}
                </form>
            </section>
            
            
        </>
    )
    
};

export default LoginForm;