import React from 'react';
import styles from './Input.module.css';
import Error from '../../Error/Error';
import { InputStyled } from './styles';

const Input = ({label, type, name, value, onChange, error, onBlur, whitelabel}) => {

    if(type==='checkbox'){
        return(
            <label> Mostrar a senha
                <InputStyled whitelabel={whitelabel} type="checkbox"/>
                <span className={styles.checkmark}/>
            </label>
        )
    }else{

        return (
            <div className={styles.wrapper}>
            <label 
                htmlFor={name} 
                className={styles.label}
            >{label}
            </label>
            <InputStyled 
                whitelabel={whitelabel} 
                id={name} 
                name={name} 
                // className={styles.input}  
                type={type} 
                onChange={onChange} 
                value={value}
                onBlur={onBlur}
            />
            <Error whitelabel={whitelabel} data-testid="error" error={error} />
        </div>
        )
    }

};

export default Input;