import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import tokenPost from '../services/tokenPost';

import swal from 'sweetalert';

import whitelabelDefault from '../whitelabel.json';

import { getMenu } from '../firestore/getMenu';
import { getWhitelabel } from '../firestore/getWhitelabel';

export const UserContext = createContext();

export const UserStorage = ({ children }) => {

    const [data, setData] = useState(null);
    const [token, setToken] = useState(null);
    const [login, setLogin] = useState(null);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(null);
    const [mapData, setMapData] = useState([]);
    const [filterDownload, setFilterDownload] = useState(null);
    const [filterLists, setFilterLists] = useState(null);
    const [accessDenied, setAccessDenied] = useState(null)

    const history = useHistory();

    const [whitelabel, setWhitelabel] = useState(whitelabelDefault)
    const [menu, setMenu] = useState();

    async function userLogin(email, password) {

        setError(null);
        setLoad(true);

        const response = await tokenPost({ email, password });

        const userRes = response.data;

        if (userRes.status === 'error') {
            setError(userRes.results[0].message);
            setLogin(false);
            setLoad(false);

        } else {

            swal("Sucesso!", "Bem-vindo ao Loggisto!", "success", {
                timer: 2000,
                button: false,
            });
            const userLogin = await userRes.results[0];

            const { token } = await userLogin;
            const { user } = await userLogin;

            window.localStorage.setItem('yatto_token', token);
            setLogin(true);
            setData(user);
            setToken(token);

            const id = (user.entity.entityTypeID).toString();

            getWhitelabel(id, setWhitelabel)
            getMenu(id, setMenu)

            window.localStorage.setItem('yatto_userLatLong', [user.entity.latitude, user.entity.longitude]);

            const yattoop = window.localStorage.getItem('yattoop');
            if (yattoop === 'undefined') {
                history.push('/');
            } else {
                history.push('/yattoop/' + yattoop);
            }
        }

    };

    async function userLogout() {
        setLogin(false)
        setData(null);
        setToken(null);
        setError(null);
        setLoad(false);
        window.localStorage.removeItem('yatto_token');
        history.push('/login')
    }
    return (
        <UserContext.Provider value={{
            userLogin, data, userLogout, error, load, login, token, setLogin, setData,
            setMapData, mapData,
            filterDownload, setFilterDownload,
            filterLists, setFilterLists,
            whitelabel, setWhitelabel,
            menu, setMenu,
            accessDenied, setAccessDenied
        }}>
            {children}
        </UserContext.Provider>
    )
};





