import {React, useContext} from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Typography from '@material-ui/core/Typography';

import { UserContext } from '../../store/UserContext';

import { HeaderStyled, Icon, Infos, PointerExit } from './styles';
import { Link } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import getImage from '../../firestore/getImage';

const Header = () => {

  const { data, userLogout, whitelabel } = useContext(UserContext); // data contem as informações do usuário

  const icon = getImage(whitelabel.systemProject[0].logo.icon)

  const history = useHistory();

  if(data === null){

    return (
      <HeaderStyled whitelabel={whitelabel.systemProject[0].colors}>
        <Link href={"/"} underline="none"> 
          <Icon image={icon} onClick={() => history.push("/")}/>
        </Link>
        <Infos>
          <Typography >Bem vindo </Typography>
          <PointerExit>
            <ExitToAppIcon onClick={userLogout}/>
          </PointerExit>
        </Infos>
    </HeaderStyled>
    )
  }else{
    return (
      <HeaderStyled whitelabel={whitelabel.systemProject[0].colors}>
        <Icon image={icon} onClick={() => history.push("/")}/>
        <Infos>
          <Typography >Bem vindo, {data.name} </Typography>
          <PointerExit>
            <ExitToAppIcon onClick={userLogout}/>
          </PointerExit>
        </Infos>
      </HeaderStyled>
    )
  }

}

export default Header;