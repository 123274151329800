import React, { useContext, useState } from 'react';
import { 
    MapContainer, 
    LayersControl, 
    TileLayer, 
    AttributionControl,
    ZoomControl,
} from 'react-leaflet'
// import MarkerClusterGroup from 'react-leaflet-markercluster'
import MarkerClusterGroup from './MarkerClusterGroup';
import { SingleMarker } from './MarkerFactor';
import { UserContext } from '../../../store/UserContext';

import './leaflet.css';
import { useEffect } from 'react';


export default function Leaflet(props){
    
    const { mapData, data } = useContext(UserContext);
    const userData = data;
    const [map, setMap] = React.useState();
    const center = window.localStorage.getItem('yatto_userLatLong').split(","); //[lat, long]
    console.log(center)

    // useEffect(() => {

    //     //Setar localização baseado no GPS
    //     // console.log("here");
    //     // if ("geolocation" in navigator && map) {
    //     //     console.log(map);
    //     //     navigator.geolocation.getCurrentPosition(function (cPosition) {
    //     //     map.panTo([cPosition.coords.latitude, cPosition.coords.longitude]);
    //     //     });
    //     // }

    //     if (userData && map) {

    //         //Centralizar mapa no local da empresa
    //         map.panTo([userData.entity.latitude, userData.entity.longitude]);
    //     }

    // }, [map]);

    //Dados do Tooltip
    // const [valorResiduo, setValorResiduo] = useState(0)
    // const [entityType, setEntityType] = useState("")
    // const [xpos, setXpos] = useState(0)
    // const [ypos, setYpos] = useState(0)

    const styles={
        
        overflow: "hidden",
    }

    return(
        <>
            {/* <div 
            id="tooltip"
            className="leaflet-popup leaflet-popup-content-wrapper" 
            style={{top: ypos,
                    left: xpos,
                    width: 200,
                    opacity: opacidade}}
            >
                <p><strong>Valor:</strong></p>
                <p><span id="value">{valorResiduo}</span></p>
                <p><strong>Entidade:</strong></p>
                <p><span id="entityType">{entityType}</span></p>
            </div> */}

            <MapContainer 
                center={center} 
                zoom={13} 
                scrollWheelZoom={true}
                attributionControl={false}
                whenCreated={setMap}
                zoomControl={false}
                style={styles}
                >
                <ZoomControl position="topright"/>
                <LayersControl position="topright">
                    
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                        <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    <AttributionControl position="bottomright" prefix={false} />

                    {
                        userData ?

                        <SingleMarker 
                            latitude={userData.entity.latitude} 
                            longitude={userData.entity.longitude}
                            entityTypeID={userData.entity.entityTypeID} 
                            userData={userData}
                        /> : null
                    }

                    <MarkerClusterGroup
                    key="entityCluster"
                    spiderfyDistanceMultiplier={2}
                    showCoverageOnHover={false}
                    markers={mapData}
                    >
                    </MarkerClusterGroup>
                </LayersControl>
            </MapContainer>
        </>
    )
}

