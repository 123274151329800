import styled, { createGlobalStyle } from 'styled-components';

const ScrollbarStyle = createGlobalStyle`

::-webkit-scrollbar {
    width: 8px;
  }
  
  /* fundo scroll */
  ::-webkit-scrollbar-track {
    background: #DDD;
    border-radius: 10px;
  }
  
  /* barrinha de rolagem */
  ::-webkit-scrollbar-thumb {
    background: ${props => `#${props.whitelabel.neutralSecondaryColor}`};
    border-radius: 10px;
}

/* barrinha de rolagem rolando */
::-webkit-scrollbar-thumb:hover {
    
    background: ${props => `#${props.whitelabel.primaryColor}`};
  } 

`

export {ScrollbarStyle}