import React,  { useState } from "react";
import { Switch, Route} from 'react-router-dom';

//Context
import { UserStorage } from '../store/UserContext';
import PrivateRoute from '../store/PrivateRoute';

//Componentes
import Login from './Login/Login/Login';
import { Sidebar } from "../parts/Sidebar/navigation/Sidebar";
import { ScrollbarStyle } from "../parts/Sidebar/styles/ScrollbarStyle";

//Rotas
import Home from './Home/Home';
import {UploadArea}  from  './UploadArea/UploadArea'
import MapsArea from './MapsArea/MapsArea';
import ListsArea from './ListsArea/ListsArea';
import Header from "../parts/Header/Header";
import Filter from "../parts/Filter/Filter";
import CriticPoints from "./CriticPoints/CriticPoints";


const ComponentList = {
    'Home' : Home,
    'CriticPoints' : CriticPoints,
    'MapsArea': MapsArea,
    'ListsArea' : ListsArea,
    'UploadArea' : UploadArea,
};

function bringView (route, component, privado) {

    if (privado){

        return (
            // <PrivateRoute exact path={route} render={() => component}/>
            <PrivateRoute exact path={route} component={component}/>
        )
    } else {

        return (
            <Route exact path={route} render={() => component}/>
        )
    }
}

function Routes (props) {
    
    const [reqControll, setReqControll] = useState(0);
    const [ userRoutes, setUserRoutes ] = useState(props.userRoutesRequire);

    return (
        <UserStorage>
            <Switch>
                {/* Paths */}
                {bringView("/login/yattoop/:yattoop/", <Login />, false)}
                {bringView("/login", <Login />, false)}

                {/* privado */}
                <>
                    <Header />
                    {/* <ScrollbarStyle /> */}
                    <Sidebar setReqControll={setReqControll}/>
                   

                    <> 
                    {bringView("/", ComponentList['Home'], true)}
                        {userRoutes.map((area) => {
                            if(area.filter === true){
                                return(
                                    <>
                                        <Filter />
                                        {bringView(area.url, ComponentList[area.component], true)}
                                    </>
                                )
                            }else{
                                return (
                                    bringView(area.url, ComponentList[area.component], true)
                                )
                            }   
                        })}
                    </>
                </>
            </Switch>
        </UserStorage>
    )
};

export default Routes;
