import React, { useContext } from 'react';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';

import { UserContext }from '../../store/UserContext';
import { makeStyles } from '@material-ui/styles';

const Fluter = () => {

    const { whitelabel } = useContext(UserContext);
    
    const useStyles = makeStyles({
        root: {
            position:  'fixed',
            zIndex: '1000',
            left: '90%',
            top: '90%',
            color: `#${whitelabel.systemProject[0].colors.whiteColor}`,
            backgroundColor: `#${whitelabel.systemProject[0].colors.primaryColor}`,

            "&:hover" : {
                backgroundColor: `#${whitelabel.systemProject[0].colors.neutralPrimaryColor}`
            }
        }
    })
    
    const classes = useStyles();


    // Talvez seja uma boa alterar o estado do componente Filter ao clicar.


    function onclick(){
        var filter = document.getElementById("filter");
        
        if (filter.style.display !== 'none'){
            filter.style.display = 'none';
        }else if(filter.style.display !== 'flex'){
            filter.style.display = 'flex';
        }     
    }
 
    return (
        <Fab aria-label="filter" id="fluter" classes={{root: classes.root}} onClick={onclick}>
            <FilterListIcon/>
        </Fab>
     
    )
}

export default Fluter;