import React, { useState, useEffect, useCallback, useContext} from "react";

import { uniqueId } from 'lodash';
import fileSize from 'filesize';

import {Upload} from '../../parts/Upload/Upload';

import Button from '../../parts/Form/Button/Button'

import khonsu from '../../services/khonsu';

import { Container, Content } from './styles';

import FileList from '../../parts/FileList/FileList';
import { UserContext } from "../../store/UserContext";
import CheckingRoute from "../../store/checkingRoute";


// Essa função monta um novo objeto identificado como o arquivo (file). Ele contém o próprio file e novas propriedades legíveis, como o tamanho do arquivo.
const structFile = (files) => {

  
  const struct = files.map(file => ({
    file,
    id: uniqueId(),
    name: file.name,
    readableSize: fileSize(file.size),
    progress: 0,
    uploaded: false,
    error: false,
    message : null
  }));
  
  return struct;
}

//Essa função auxilia a mudar (atualizar) as propriedades do objeto file quando ele retorna do backend. É usada na processUpload.
const returnUp = (id, uploadedFile, data) => {
  return id === uploadedFile.id
  ? { ...uploadedFile, ...data }
  : uploadedFile;
};

const UploadArea = () => {

  const { menu } = useContext(UserContext);
  CheckingRoute(menu, 4); // ID da área 4

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [button, setButton] = useState(false);
  
  const { whitelabel } = useContext(UserContext);
  
  // Esse efeito assiste o estado do botão para casos em que ele se encontra desabilitado(false) e habilitado(true)
  useEffect(() => {
    if(button === false){
      document.querySelector("Button").disabled = true;
    }else{
      document.querySelector("Button").disabled = false;
    }
  }, [button]);

  //Aqui acontece um mapeamento pelo arquivo certo a ser atualizado e logo após, a chamada da função que atualiza (returnUp)
  const updateFile = (id, data) => {
    setUploadedFiles((prevState) => prevState.map(uploadedFile => returnUp(id, uploadedFile, data)));
  };

  // Essa é a função responsável pela requisição (entrega do file ao backend)
  const processUpload = (uploadedFile) => {

    const token = localStorage.getItem('yatto_token')

    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    khonsu
      .post("/upload/excel", data, {
        headers : {
          'Accept': 'application/json', 
          'Content-Type': 'multipart/form-data',
          'Authorization': `bearer ${token}`
        },
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          updateFile(uploadedFile.id, {
            progress : progress
          });
        }
      })
      .then(response => {

        if(response.data.results){

          updateFile(uploadedFile.id, {
            uploaded: true,
            message: "Upload realizado com sucesso."
          });

        }else{

          const url = window.URL.createObjectURL(new Blob([response.data], {type:'text/plain'}));
          const link= document.createElement("a");
  
          link.href = url
          link.setAttribute("download", 'relatorio-de-erro_'+uploadedFile.name.slice(0, -5)+'_.txt');
  
          document.body.appendChild(link)
  
          link.click()
  
          updateFile(uploadedFile.id, {
            error: true,
            message:'Verifique o relatório de erros.'
          });

        }

      })
      .catch((error) => {
        updateFile(uploadedFile.id, {
          error: true,
          message: 'O servidor não aceita esse tipo de arquivo.'
        });
      });
  };
  
  // Essa função é executada quando cliclamos no botão "upload". Ela realiza um map entre o estado de files da aplicação, idenfiticando quais tem a propriedade uploaded como false e mandando esse file para o backend, com a função processUpload()
  const filesUpload = () => {
    setButton(prevState => !prevState)

    uploadedFiles.map((file) => {
      if (file.uploaded === false) {
        return processUpload(file, setUploadedFiles);
      }else{
        return null;
      } 
    },);
    
    setUploadedFiles(uploadedFiles)
  };

  // Essa é a primeira função a ser executada quando um arquivo é entregue ao Upload. Ela monta um objeto contendo as informações de cada arquivo (na função structFile) e atualiza o estado files da aplicação. Além de setar ao botão um novo valor.
  const handleUpload = useCallback((files) => {
    const filesStruct = structFile(files);
    setUploadedFiles((prevState) => prevState.concat(filesStruct))
    setButton(true)

  }, []);

  //Essa função é responsável por realizar o delete de algum arquivo do nosso vetor. 
  const handleDelete  = useCallback((id) => {
    setUploadedFiles((prevState) => prevState.filter(file => file.id !== id))
  }, []);


  return (
    <div data-test="upload-area">
      <Container>
        <Content whitelabel={whitelabel.systemProject[0].colors}>
          <Upload onUpload={handleUpload} />
            {!!uploadedFiles.length && (
              <FileList files={uploadedFiles} onDelete={handleDelete} />
            )}
            <Button onClick={filesUpload} whitelabel={whitelabel.systemProject[0].colors}>Upar</Button>
        </Content>
      </Container>
    </div>
  );
  
};

export {UploadArea, returnUp, structFile};