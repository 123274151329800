import React, { useContext } from 'react';
import { Container, FileInfo, Preview, Message } from './styles'
import  {CircularProgressbar}  from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { MdCheckCircle, MdError, MdDeleteForever} from 'react-icons/md'

import { UserContext } from '../../store/UserContext';

import excelIcon from '../../assets/excel.png'

const FileList = ({files, onDelete}) => {

    const { whitelabel } = useContext(UserContext);

    return(
        <Container whitelabel={whitelabel.systemProject[0].colors}>
            {files.map( uploadedFile => (
                <div>
                <li key={uploadedFile.id}>
                    <FileInfo whitelabel={whitelabel.systemProject[0].colors}>
                        <Preview uploaded={uploadedFile.uploaded} src={excelIcon}/>
                        <div>
                            <strong>{uploadedFile.name}</strong>
                            <span>
                                {uploadedFile.readableSize}
                               
                            </span>
                        { uploadedFile.uploaded && <Message whitelabel={whitelabel.systemProject[0].colors} error={uploadedFile.error}>{uploadedFile.message}</Message>}
                        { uploadedFile.error && <Message whitelabel={whitelabel.systemProject[0].colors} error={uploadedFile.error}>{uploadedFile.message}</Message> }
                        </div>
                    </FileInfo>
                    <div style={{display:'flex'}}>
                        <MdDeleteForever size={24} color={whitelabel.systemProject[0].colors.gray} style={{marginRight:'3rem'}}onClick={() => onDelete(uploadedFile.id)}/>
                        {!uploadedFile.uploaded && !uploadedFile.error && (
                            <CircularProgressbar
                            styles={{
                                root: { width: 24},
                                path: { stroke: `#${whitelabel.systemProject[0].colors.primaryColor}`}
                            }}
                                strokeWidth={10}
                                value={uploadedFile.progress}
                                />
                                )}

                        { uploadedFile.uploaded && <MdCheckCircle size={24} color={'#'+whitelabel.systemColor[0].colors.primaryColor}/> }
                        
                        { uploadedFile.error && <MdError size={24} color={'#'+whitelabel.systemProject[0].colors.errorColor}/> }
                    </div>
                </li>
                </div>
            ))}
        </Container>
    )
};

export default FileList;