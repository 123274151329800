import React, { useContext} from 'react';
import { ButtonStyled } from './styles'

import { UserContext } from '../../../store/UserContext';

const Button = ({children, ...props}) => {
  
    const { whitelabel } = useContext(UserContext);

    return (
        <ButtonStyled {...props} whitelabel={whitelabel.systemProject[0].colors}>
            {children}
        </ButtonStyled>
    )
};

export default Button;