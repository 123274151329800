import React, { useContext, useEffect, useState } from 'react';
import style from './Filter.module.css';

import { UserContext } from '../../store/UserContext';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import filterGet from '../../services/filterGet';
import mapGet from '../../services/mapGet';
import Button from '../Form/Button/Button';

import filterFields from './FilterFields.js';

import { Multiselect } from './multiselect-react-dropdown';

const Filter = () => {

    const localToken = window.localStorage.getItem('yatto_token');
    const yattoop = window.localStorage.getItem('yattoop');

    const { setMapData, } = useContext(UserContext);
    const [filterData, setFilterData] = useState('');

    const { setFilterDownload, setFilterLists, whitelabel } = useContext(UserContext);

    let valuesSelected = []; // esse vetor vai ser utilizado para armazenar as opções selecionadas

    const inicialFilter = {
        entitySLRStatus: 31002
    }

    const getMapData = async () => {
        const data = await mapGet(localToken, inicialFilter)
        setMapData(data.data.results[0].entity)
    }

    async function getSelectionFilter() {
        const response = await filterGet();
        return setFilterData(response.data.results[0])
    }

    useEffect(() => {

        switch (yattoop) {
            case 'mapas': {
                getSelectionFilter()
                setFilter({ ...filter, ['entitySLRStatus']: '31002' })
                getMapData()
            }

                break;

            case 'download': {
                getSelectionFilter()
            }
                break;
            case 'listas': {
                getSelectionFilter()
            }
                break;
            case 'pontos_criticos': {
                getSelectionFilter()
            }
                break;

            default: {
                return null;
            }
        }


    }, []);

    const [filter, setFilter] = useState(
        filterFields.reduce((acc, field) => {
            return {
                ...acc,
                [field.id]: '',
            };
        }, {}),
    );

    function handleChange({ target }) {
        let { id, value } = target;
        setFilter({ ...filter, [id]: value })
    }

    function handleSubmit(event) {
        event.preventDefault();

        switch (yattoop) {
            case 'mapas':

                const getMapData = async () => {

                    const data = await mapGet(localToken, filter)

                    if (data) {
                        setMapData(data.data.results[0].entity)
                    } else {
                        setMapData([])
                    }
                }
                getMapData()

                break;

            case 'download':

                console.log(filter)
                setFilterDownload(filter)
                break;

            case 'listas':
                console.log(filter)
                setFilterLists(filter)
                break;

            case 'pontos_criticos': {
                console.log(filter)
                setFilterLists(filter)
            }
                break;

            default:
                return null;

        }


    }

    function onSelect(selectedList, id) {
        selectedList.map((value) => {
            valuesSelected.push(value.ID)
            setFilter({ ...filter, [id]: valuesSelected.toString() })
        })
    }

    function onRemove(selectedList, id) {
        if (selectedList.length === 0) {
            setFilter({ ...filter, [id]: "" })
        } else {
            onSelect(selectedList, id)
        }
    }


    useEffect(() => {
        console.log("filter", filter)
    }, [filter]);

    return (
        <form id="filter" className={style.filter} onSubmit={handleSubmit}>
            {filterFields.map(({ id, label, type, placeholder, options }) => {

                if (type === 'select' && id === 'entitySLRStatus' && yattoop === 'mapas') {
                    return (
                        <div key={id}>
                            <InputLabel> {label} </InputLabel>
                            <Multiselect
                                options={filterData[options]}
                                displayValue="name"
                                emptyRecordMsg="Não há opções disponíveis"
                                placeholder="Selecione"
                                id={id}
                                selectedValues={[{ ID: 31001, name: 'Ativado' }]}
                                onChange={handleChange}
                                value={filter[id]}
                                onSelect={((selectedList) => onSelect(selectedList, id))}
                                onRemove={((selectedList) => onRemove(selectedList, id))}
                            />
                        </div>
                    )
                }
                else if (type === 'select') {
                    return (
                        <div key={id}>
                            <InputLabel> {label} </InputLabel>
                            <Multiselect
                                options={filterData[options]}
                                displayValue="name"
                                emptyRecordMsg="Não há opções disponíveis"
                                placeholder="Selecione"
                                id={id}
                                onChange={handleChange}
                                value={filter[id]}
                                onSelect={((selectedList) => onSelect(selectedList, id))}
                                onRemove={((selectedList) => onRemove(selectedList, id))}
                            />
                        </div>
                    )
                }

                else {
                    return (
                        <div key={id}>
                            <InputLabel> {label} </InputLabel>
                            <Input
                                type={type}
                                id={id}
                                value={filter[id]}
                                onChange={handleChange}
                                placeholder={placeholder}
                                color="success"
                                fullWidth="false"
                            />
                        </div>
                    )
                }
            }

            )}

            <Button>Filtrar</Button>

        </form>
    )

}

export default Filter;
