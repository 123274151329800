import React, { useContext } from 'react';
import './tooltip.css'

import Leaflet from '../../parts/Maps/Leaflet/LeafletMap';

import Fluter from '../../parts/Fluter/Fluter';
import { UserContext } from '../../store/UserContext';
import CheckingRoute from '../../store/checkingRoute';


const MapsArea = () => {

    const { menu } = useContext(UserContext);
    CheckingRoute(menu, 1) // ID da área;

    window.localStorage.setItem('yattoop', 'mapas');

    return (
        <div>
            
            <section id="setor" style={{
                width: "100%",
                height: "calc(100vh - 4rem)",
                paddingLeft: "6rem",
            }}>
                {/* <h1>Área de Mapasss</h1> */}
                {/* <Filter id="filter"/> */}
                <Fluter />
                <Leaflet />
            </section>
        </div>

    )
}

export default MapsArea;
